import Image from "next/image";
import { useEffect, useState } from "react";

const RentalCompanyList = () => {
  const [listShown, setListShown] = useState(true);

  useEffect(() => {
    setListShown(false);
  }, []);

  return (
    <section
      id="rental-company-list"
      className="bg-white px-[13px] py-[22px] lg:py-[60px] mt-[40px]"
    >
      <div className="max-w-7xl mx-auto">
        <h2 className="font-extrabold text-[18px] lg:text-[30px] text-my-dark-blue mb-[22px] lg:mb-[40px]">
          List of Car Rental Companies
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-5 gap-y-2 lg:gap-x-[75px] lg:gap-y-[36px] lg:border border-[#D8DFEE] rounded-[20px] lg:py-5">
          {rentalCompanyData.slice(0, 4).map((company: any) => {
            return (
              <div
                key={company.id}
                className="flex flex-row lg:flex-col items-center justify-start lg:justify-start gap-3 lg:gap-0 py-[10px] px-1"
              >
                <div className="h-[30px] w-[60px] lg:w-[120px] lg:h-[60px]">
                  <Image
                    height={60}
                    width={120}
                    alt={company.name}
                    src={company.img}
                    className="shadow-md lg:rounded-lg rounded-[4px]"
                  />
                </div>
                <p className="font-medium text-sm lg:text-[16px] text-my-dark-blue lg:pt-2 lg:text-center">
                  {company.name}
                </p>
              </div>
            );
          })}
          {listShown &&
            rentalCompanyData.slice(4).map((company: any) => {
              return (
                <div
                  key={company.id}
                  className="flex lg:hidden flex-row lg:flex-col items-center justify-start lg:justify-start gap-3 lg:gap-0 py-[10px] px-1"
                >
                  <div className="h-[30px] w-[60px] lg:w-[120px] lg:h-[60px]">
                    <Image
                      height={60}
                      width={120}
                      alt={company.name}
                      src={company.img}
                      className="shadow-md lg:rounded-lg rounded-[4px]"
                    />
                  </div>
                  <p className="font-medium text-sm lg:text-[16px] text-my-dark-blue lg:pt-2 lg:text-center">
                    {company.name}
                  </p>
                </div>
              );
            })}
          {rentalCompanyData.slice(4).map((company: any) => {
            return (
              <div
                key={company.id}
                className="flex-row lg:flex-col hidden lg:flex items-center justify-start lg:justify-start gap-3 lg:gap-0 py-[10px] px-1"
              >
                <div className="h-[30px] w-[60px] lg:w-[120px] lg:h-[60px]">
                  <Image
                    height={60}
                    width={120}
                    alt={company.name}
                    src={company.img}
                    className="shadow-md lg:rounded-lg rounded-[4px]"
                  />
                </div>
                <p className="font-medium text-sm lg:text-[16px] text-my-dark-blue lg:pt-2 lg:text-center">
                  {company.name}
                </p>
              </div>
            );
          })}
          {!listShown && (
            <button
              className="border-t-[0.5px] border-[#D8DFEE] pt-[18px] text-[#004DE3] lg:hidden block"
              onClick={() => setListShown(true)}
            >
              View all
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

let rentalCompanyData = [
  {
    id: 1,
    img: "/rental-company-list/enterprise.svg",
    name: "Enterprise",
    locationCount: 7,
  },
  {
    id: 2,
    img: "/rental-company-list/national.svg",
    name: "National",
    locationCount: 7,
  },
  {
    id: 3,
    img: "/rental-company-list/alamo.svg",
    name: "Alamo",
    locationCount: 7,
  },
  {
    id: 4,
    img: "/rental-company-list/hertz.svg",
    name: "Hertz",
    locationCount: 7,
  },
  {
    id: 5,
    img: "/rental-company-list/avis.svg",
    name: "Avis",
    locationCount: 7,
  },
  {
    id: 6,
    img: "/rental-company-list/budget.svg",
    name: "Budget",
    locationCount: 7,
  },
  {
    id: 7,
    img: "/rental-company-list/sixt.svg",
    name: "Sixt",
    locationCount: 7,
  },
  {
    id: 8,
    img: "/rental-company-list/dollar.svg",
    name: "Dollar",
    locationCount: 7,
  },
  {
    id: 9,
    img: "/rental-company-list/ace.svg",
    name: "Ace rent a car",
    locationCount: 7,
  },
  {
    id: 10,
    img: "/rental-company-list/europcar.svg",
    name: "Europcar",
    locationCount: 7,
  },
  {
    id: 11,
    img: "/rental-company-list/turo.svg",
    name: "Turo",
    locationCount: 7,
  },
  {
    id: 12,
    img: "/rental-company-list/localiza.svg",
    name: "Localiza",
    locationCount: 7,
  },
  {
    id: 13,
    img: "/rental-company-list/autorentals.svg",
    name: "Autorentals",
    locationCount: 7,
  },
  {
    id: 14,
    img: "/rental-company-list/thrifty.svg",
    name: "Thrifty",
    locationCount: 7,
  },
  {
    id: 15,
    img: "/rental-company-list/greenmotion.svg",
    name: "Greenmotion",
    locationCount: 7,
  },
];

//TODO: Need 15 rental providers to include in list

export default RentalCompanyList;
